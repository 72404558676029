<template>
  <div class="pb-20">
    <div class="grid grid-cols-1 md:grid-cols-2 mb-10">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Processing Users</h2>
        <router-link :to="{ name: 'users-new' }" class="text-sm text-gray-700">
          + Add new user
        </router-link>
      </div>
    </div>

    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajax-pagination="true"
        :columns="columns"
        :query="users.query"
        :actions="actions"
        :url="users.url"
        :on-click="click"
        sort="created_at"
        :filters="filters"
        order="desc"
        ref="table"
        :dropdown="false"
      >
        <template #header>
          <nav class="border-b-2 border-blue-200 px-8 mb-5">
            <ul class="flex flex-wrap items-center w-full">
              <li
                class="-mb-2px -mt-5 pr-20 relative"
                v-for="(filter, index) in filters"
                :key="index"
              >
                <a
                  href="#!"
                  class="block border-b-2 cursor-pointer duration-300 font-bold pt-3 pb-4 relative text-blue-400 hover:text-gray-600 text-sm"
                  :class="{
                    'border-blue-500 text-blue-500 hover:text-blue-500':
                      currentFilter === filter,
                  }"
                  @click.prevent="toggleFilter(filter)"
                >
                  <span class="mr-2">{{ filter.title }}</span>
                </a>
                <span
                  class="absolute h-3 w-1 border-r-2 border-blue-200 right-10 top-1/2 transform -translate-y-1/2"
                />
              </li>
            </ul>
          </nav>
          <search-form
            v-model="users.query"
            class="mb-5"
            placeholder="Search for user's name, status, etc."
            @submit="reloadTable"
          />
        </template>
      </datatable>
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      additionForm: this.$options.basicForm([{ name: 'admins', value: [] }]),
      currentFilter: null,
      count: null,
      users: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/users/status/process`,
        query: '',
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'User ID',
          },
          {
            name: 'name',
            th: 'Name',
            render: (user) => `${user.name} ${user.last_name}`,
          },
          {
            name: 'merchant',
            th: 'Merchant',
            render: ({ merchant }) => merchant?.business_name,
          },
          {
            name: 'email',
            th: 'Email Address',
          },
          {
            name: 'progress',
            th: 'Progress',
            render: (user) => {
              return this.progressIcons(user);
            },
            sortable: false,
          },
          {
            name: 'status',
            th: 'Status',
            render: (user, status) => {
              return this.statusBadge(status, user);
            },
            sortable: false,
          },
          {
            name: 'admins',
            th: 'Assigned To',
            render: (user) => {
              return this.assignedAdmin(user);
            },
            sortable: false,
          },
          {
            name: 'approved_by',
            th: 'Approved By',
            render: ({ credit_limit }) =>
              credit_limit
                ? credit_limit?.authorizer_id
                  ? `<div class="badge badge-blue-soft-outline">
                      ${credit_limit?.authorizer?.name} ${credit_limit?.authorizer?.last_name}
                    </div>`
                  : '<div class="badge badge-purple-soft-outline">Loan Bot</div>'
                : 'N/A',
          },
          {
            name: 'approved_at',
            th: 'Date Approved',
            render: ({ credit_application }) =>
              credit_application?.attended_at
                ? this.$moment(credit_application?.attended_at).format(
                    'MMM Do YYYY'
                  )
                : 'N/A',
          },
          {
            name: 'created_at',
            th: 'Date Registered',
            render: (user, created_at) =>
              this.$moment(created_at).format('MMM Do YYYY'),
          },
        ],
      }),
    };
  },
  computed: {
    columns() {
      const columns = [
        {
          name: 'id',
          th: 'User ID',
        },
        {
          name: 'name',
          th: 'Name',
          render: (user) => `${user.name} ${user.last_name}`,
        },
        {
          name: 'merchant',
          th: 'Merchant',
          render: ({ merchant }) => merchant?.business_name,
        },
        {
          name: 'email',
          th: 'Email Address',
        },
        {
          name: 'progress',
          th: 'Progress',
          render: (user) => {
            return this.progressIcons(user);
          },
          sortable: false,
        },
        {
          name: 'status',
          th: 'Status',
          render: (user, status) => {
            return this.statusBadge(status, user);
          },
          sortable: false,
        },
        {
          name: 'admins',
          th: 'Assigned To',
          render: (user) => {
            return this.assignedAdmin(user);
          },
          sortable: false,
        },
        {
          name: 'approved_by',
          th: 'Approved By',
          render: ({ credit_limit }) =>
            credit_limit
              ? credit_limit?.authorizer_id
                ? `<div class="badge badge-blue-soft-outline">
                    ${credit_limit?.authorizer?.name} ${credit_limit?.authorizer?.last_name}
                  </div>`
                : '<div class="badge badge-purple-soft-outline">Loan Bot</div>'
              : 'N/A',
        },
        {
          name: 'approved_at',
          th: 'Date Approved',
          render: ({ credit_application }) =>
            credit_application?.attended_at
              ? this.$moment(credit_application?.attended_at).format(
                  'MMM Do YYYY'
                )
              : 'N/A',
        },
        {
          name: 'created_at',
          th: 'Date Registered',
          render: (user, created_at) =>
            this.$moment(created_at).format('MMM Do YYYY'),
        },
      ];
      if (this.currentFilter?.name === 'express-approved') {
        columns.splice(
          3,
          1,
          {
            name: 'email',
            th: 'Email',
            render: (user) => user?.official_email,
          },
          {
            name: 'company',
            th: 'Company',
            render: (user) => user?.profile?.employer,
          },
          {
            name: 'salary',
            th: 'Salary',
            render: (user) => user?.profile?.salary,
          },
          {
            name: 'phone',
            th: 'Phone',
            render: (user) => user?.phone_no,
          }
        );
      }
      return columns;
    },
    actions() {
      if (this.currentFilter?.name === 'all' || !this.currentFilter) return undefined;
      return [
        {
          text: 'Requeue',
          class:
            'bg-blue-500 text-white rounded-sm px-4 py-2 hover:bg-blue-200 hover:text-black',
          action: this.requeue,
        },
      ];
    },
    filters() {
      const filters = [
        { title: 'All', name: 'all' },
        {
          title: `Bank Statement (${this.count?.userWithBankStatment || 0})`,
          name: 'bank-statement',
        },
        {
          title: `Credit History (${this.count?.userWithGoodCredit || 0})`,
          name: 'only:credit-history',
        },
        {
          title: `Work Email (${this.count?.userWithWorkEmail || 0})`,
          name: 'only:work-email',
        },
      ];
      return filters;
    },
  },
  mounted() {
    this.getCount();
  },
  methods: {
    getCount() {
      this.sendRequest('admin.assignments.processCount', 'processing', {
        success: (response) => {
          this.count = response.data.data;
        },
        error: (error) => {
          console.log(error);
        },
      });
    },

    click(user) {
      this.users.selected = user;
      this.$refs.userModal.open();
    },
    requeue({ id }) {
      this.sendRequest('admin.assignments.requeue', {
        data: {
          user_id: id,
          currentFilter: this.currentFilter?.name,
        },
        success: () => {
          this.reloadTable();
          this.$success({
            title: 'Notification!',
            body: 'Customer Requeued Successfully',
            button: 'Okay',
          });
        },
        error: (error) => {
          this.additionForm.error = error;
        },
      });
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
      this.$nextTick(() => {
        this.$refs.table.renderHeaders();
        this.$refs.table.renderData();
      });
    },
    authorizer(creditApplication) {
      creditApplication?.authorizer_id
        ? `${creditApplication?.authorizer?.name} ${creditApplication?.autorizer?.last_name}`
        : 'Loan Bot';
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.users.selected = null;
    },
  },
};
</script>
